.navigation {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    border-bottom: 2px solid $grey;

    .container {
        height: 50px;
        display: flex;
        flex-direction: row;

        .user-details {
            margin-top: 12px; }

        ul {
            height: 50px;
            display: block;
            list-style: none;
            display: flex;
            flex: 1;
            justify-content: flex-start;

            li {
                margin-right: 20px;

                a {
                    padding: 13px;
                    display: block;
                    text-decoration: none;
                    cursor: pointer; }

                .active {
                    border-bottom: 2px solid $blue; } } } } }
