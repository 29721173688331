@import "variables";
@import "navigation";
@import "auth";


header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px; }

.auto-complete {
    margin-top: -17px;
    width: inherit;
    padding: 5px;
    position: absolute;
    box-sizing: border-box;
    max-height: 150px;
    border: 1px solid $blue;
    background: white;
    overflow-y: auto;
    display: none;

    &__item {
        padding: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        border-bottom: 1px solid $grey;

        &:last-child {
            border-bottom: none; } } }

.clear-all {
    font-size: 10px;
    cursor: pointer;
    color: $blue; }

.print-header {
    width: 100%;
    font-size: 25px;
    text-align: center;
    display: none; }

.print-footer {
    display: none;
    flex-direction: row;
    justify-content: space-between; }

.footer-date {
    font-size: 12px; }

.new-customer {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    display: none;

    &-inner {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -260px;
        margin-left: -300px;
        padding: 20px;
        height: 520px;
        width: 600px;
        background: white;
        box-sizing: border-box; } }

.pagination {
    list-style: none;
    display: flex;

    li {
        width: 30px;
        height: 30px;
        border: 1px solid $grey;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;

        &:first-child {
            margin-left: 0; } }

    li a {
        display: block;
        padding: 10px;
        padding: 10px;
        text-decoration: none; }

    .active {
        border-color: $blue;
        background: $blue;
        color: white; } }

.option-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

@media print {
    .navigation, .form, .header {
        display: none; }

    .print-header {
        display: block; }

    .print-footer {
        display: flex; }

    table {
        font-size: 11px; } }
